import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import "./state-of-digital-operations-2021@gen.scss";

const GeneratedResourcesStateOfDigitalOperations2021 = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          eBook「デジタルオペレーションの現状」｜PagerDuty正規代理店（株）Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="デジタル オペレーションの現状を学び、リアルタイム作業の増加が技術チームにどのような負担をかけているかを明らかにします。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="デジタル オペレーションの現状を学び、リアルタイム作業の増加が技術チームにどのような負担をかけているかを明らかにします。"
        />

        <meta
          property="og:title"
          content="eBook「デジタルオペレーションの現状」｜PagerDuty正規代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="デジタル オペレーションの現状を学び、リアルタイム作業の増加が技術チームにどのような負担をかけているかを明らかにします。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/resources/state-of-digital-operations-2021/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/resources/state-of-digital-operations-2021/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <div className="digitalOperationsWrapper">
        <div className="digitalOperation-container">
          <div className="digitalOperation-banner !min-h-[300px]">
            <img
              src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/banner_2_d42e8f27cf.png?updated_at=2022-12-08T07:16:05.053Z"
              loading="lazy"
              alt="digitalOperation Page Banner"
              className="banner-bg"
            />
            <div className="z-[2]">
              <h1 className="banner-title">デジタルオペレーションの現状</h1>
              <p className="banner-summary">
                DXが進む今日において、技術チームの負担が増加しているデータとその対応方法をまとめたレポートです。
              </p>
              <a
                className="banner-btn !no-underline mx-auto w-[200px] min-h-[40px]"
                href="/resources/state-of-digital-operations-2021/form/"
              >
                レポートを読む
              </a>
            </div>
          </div>
          <div className="intro-section">
            <div>
              <h3 className="intro-section__title !text-[28px] !leading-9">
                リアルタイム対応の増加がどのように技術チームに負担を強いているのかを明らかにした「デジタルオペレーションの現状」の初版をお届けします。
              </h3>
              <p className="intro-section__summary">
                PagerDutyプラットフォーム上のデータを集約・分析することで、優れた運用手法がビジネスインパクト、オペレーションの健全性、およびヒューマンファクターに与える影響を明らかにしました。また、本レポートによって、デジタルおよびビジネス部門のリーダーの理解促進に少しでもお役に立てればと考えています。
                <br /> <br />
                本ページでは、レポートの中で強調されているプラットフォームの主要なインサイトをご覧いただけます。ここに記載されている指標は今後も定期的に更新され、トレンドもアップデートされていきます。
              </p>
              <a
                className="banner-btn !no-underline w-[200px] min-h-[40px]"
                href="/resources/state-of-digital-operations-2021/form/"
              >
                レポートを読む
              </a>
            </div>
            <div className="hidden md:block">
              <div>
                <img
                  src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/digital_operation_image1_2f1ce8169a.png?updated_at=2022-12-02T07:34:22.795Z"
                  loading="lazy"
                  alt="Digital Opearation Decorative Image"
                  className="md:min-h-[340px] w-full h-full md:min-w-[340px]"
                  width="350px"
                  height="350px"
                />
              </div>
            </div>
          </div>
          <div className="mb-[100px] px-4 xl:px-0">
            <h3 className="text-center mb-[30px] text-[32px] text-black font-bold leading-[46px]">
              1日あたりの主な指標 （2021年10月現在）
            </h3>
            <div className="flex justify-center items-center">
              <div className="fancy-price-box">
                <div className="fancy-price-box__container">
                  <div className="fancy-price-box__container__main-text">
                    4,400
                    <span className="fancy-price-box__container__sub-text">
                      万件
                    </span>
                  </div>
                  <p className="fancy-price-box__container__label">
                    のイベント
                  </p>
                </div>
              </div>
              <div className="fancy-price-box">
                <div className="fancy-price-box__container">
                  <div className="fancy-price-box__container__main-text">
                    140
                    <span className="fancy-price-box__container__sub-text">
                      万件
                    </span>
                  </div>
                  <p className="fancy-price-box__container__label">
                    のアラート
                  </p>
                </div>
              </div>
              <div className="fancy-price-box">
                <div className="fancy-price-box__container">
                  <div className="fancy-price-box__container__main-text">
                    66.7
                    <span className="fancy-price-box__container__sub-text">
                      万件
                    </span>
                  </div>
                  <p className="fancy-price-box__container__label">
                    のインタラプション
                  </p>
                </div>
              </div>
              <div className="fancy-price-box">
                <div className="fancy-price-box__container !border-0">
                  <div className="fancy-price-box__container__main-text">
                    7
                    <span className="fancy-price-box__container__sub-text">
                      万件
                    </span>
                  </div>
                  <p className="fancy-price-box__container__label">
                    のクリティカルインシデント
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 xl:px-0">
            <div className="cols-2 flex-col-reverse md:flex-row">
              <div className="flex-1">
                <img
                  src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/digital_operation_image2_a7c082a786.png?updated_at=2022-12-02T09:07:02.213Z"
                  loading="lazy"
                  alt="Digital Opearation Chart Image"
                  className="col-image"
                  width="500px"
                  height="308px"
                />
              </div>
              <div className="flex-1">
                <h3 className="col-text !text-[28px] !leading-9">
                  2020年、ユーザーは前年に比べ、1日平均で2時間余分に働きました。これは1年で約12週間も余分に働く計算になります。
                </h3>
                <p className="col-summary">
                  PagerDutyのデータによると、2020年の労働時間は2019年に比べ、かなり不安定であることが分かりました。インシデント対応の中心は最終的には人であるため、組織で起きる可能性のある過重労働を認識し続けることは、ビジネスチームにとっても技術チームにとっても重要なことです。
                </p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-y-10 gap-x-[50px] mb-[60px]  items-center">
              <div className="flex-1">
                <h3 className="col-text !text-[28px] !leading-9">
                  2019年から2020年にかけて、クリティカルインシデントが19％増加
                </h3>
                <p className="col-summary">
                  クリティカルインシデントは、「緊急性の高いサービスにおいて、5分以内に自動解決されず、4時間以内に確認され、24時間以内に解決されたもの」と定義しています。
                </p>
              </div>
              <div className="flex-1">
                <img
                  src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/IMAGE_fdaecfd0e9.png?updated_at=2022-12-02T09:33:08.395Z"
                  loading="lazy"
                  alt="Digital Opearation Chart Image 1"
                  className="col-image"
                  width="500px"
                  height="308px"
                />
              </div>
            </div>
            <div className="cols-2 flex-col-reverse md:flex-row">
              <div className="flex-1">
                <img
                  src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/IMAGE_1_f0422b2dfe.png?updated_at=2022-12-02T09:33:08.374Z"
                  loading="lazy"
                  alt="Digital Opearation Chart Image 2"
                  className="col-image"
                  width="500px"
                  height="308px"
                />
              </div>
              <div className="flex-1">
                <h3 className="col-text !text-[28px] !leading-9">
                  PagerDutyを使用した場合、全体で98％のノイズ抑制が可能
                </h3>
                <p className="col-summary">
                  機械学習を含むさまざまなノイズ除去技術により、4000万を超えるイベントは約100万件のアラートに圧縮されます。これは1日あたりの実インシデント数とほぼ同数になります。
                </p>
              </div>
            </div>
          </div>
          <div className="mb-[100px] pt-10 px-4 xl:px-0">
            <h3 className="text-center mb-[30px] text-[32px] text-black font-bold leading-[46px]">
              米国における2020年のインタラプションは
              <br />
              2019年と比較して
            </h3>
            <div className="flex justify-center items-center">
              <div className="fancy-price-box">
                <div className="fancy-price-box__container">
                  <div className="fancy-price-box__container__main-text">
                    9%
                    <span className="fancy-price-box__container__sub-text">
                      増加
                    </span>
                  </div>
                  <p className="fancy-price-box__container__label">
                    営業時間外のインタラプション
                  </p>
                </div>
              </div>
              <div className="fancy-price-box">
                <div className="fancy-price-box__container">
                  <div className="fancy-price-box__container__main-text">
                    7%
                    <span className="fancy-price-box__container__sub-text">
                      増加
                    </span>
                  </div>
                  <p className="fancy-price-box__container__label">
                    休日／週末のインタラプション
                  </p>
                </div>
              </div>
              <div className="fancy-price-box">
                <div className="fancy-price-box__container">
                  <div className="fancy-price-box__container__main-text">
                    5%
                    <span className="fancy-price-box__container__sub-text">
                      増加
                    </span>
                  </div>
                  <p className="fancy-price-box__container__label">
                    営業時間中のインタラプション
                  </p>
                </div>
              </div>
              <div className="fancy-price-box">
                <div className="fancy-price-box__container !border-0">
                  <div className="fancy-price-box__container__main-text">
                    3%
                    <span className="fancy-price-box__container__sub-text">
                      減少
                    </span>
                  </div>
                  <p className="fancy-price-box__container__label">
                    就寝時間中のインタラプション
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 xl:px-0">
            <div className="cols-2 flex-col md:flex-row">
              <div className="flex-1">
                <h3 className="col-text !text-[28px] !leading-9">
                  燃え尽き症候群の放置は離職率上昇に直結
                </h3>
                <p className="col-summary">
                  PagerDutyのデータサイエンスチームは、プラットフォーム対応者の離職率と営業時間外にインシデント解決に当たる頻度との関係性に注目しました。その結果、両者の間には統計的に有意な相関があることが分かりました。営業時間害に問題解決に当たる頻度が高いユーザーほど離職する可能性が高かったのです。
                </p>
              </div>
              <div className="flex-1">
                <img
                  src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Frame_409f400641.png?updated_at=2022-12-02T09:46:45.752Z"
                  loading="lazy"
                  alt="Digital Opearation Frame Image"
                  className="col-image"
                  width="500px"
                  height="308px"
                />
              </div>
            </div>
          </div>
          <div className="pt-10 mb-[100px] px-4 xl:px-0">
            <h3 className="col-text !text-[28px] !leading-9 text-center">
              時間の経過とともにインシデント対応のレベルは向上
            </h3>
            <p className="col-summary text-center mb-[30px]">
              PagerDutyを5年以上使用しているアカウントを見ると、平均確認時間（MTTA）と平均修復時間（MTTR）の両方が、時間の経過とともに減少傾向にあります。つまり、プラットフォームを使い続けることで、インシデント対応のレベルは明らかに向上しているのです。
            </p>
            <div className="cols-2 flex-col md:flex-row">
              <div className="flex-1">
                <img
                  src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/digital_opearation_chart1_b58493a41c.png?updated_at=2022-12-02T09:56:41.020Z"
                  loading="lazy"
                  alt="Digital Opearation Chart Image 1"
                  className="col-image"
                  width="500px"
                  height="308px"
                />
              </div>
              <div className="flex-1">
                <img
                  src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/digital_opearation_chart2_6c2a748112.png?updated_at=2022-12-02T09:56:40.868Z"
                  loading="lazy"
                  alt="Digital Opearation Chart Image 2"
                  className="col-image"
                  width="500px"
                  height="308px"
                />
              </div>
            </div>
          </div>
          <div className="flex px-4 xl:px-0 flex-col md:flex-row gap-y-12 gap-x-[90px] max-w-[1100px] mx-auto mb-20 md:items-center">
            <div>
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Footer_Illustration_60c8b63149.png?updated_at=2022-12-02T10:03:51.384Z"
                loading="lazy"
                alt="Digital Opearation Footer Image"
                width="300px"
                height="300px"
                className="w-[250px] h-[250px] mx-auto md:ml-0 md:w-[300px] md:h-[300px]"
              />
            </div>
            <div className=" flex flex-col items-center">
              <h3 className="max-w-[640px] font-bold text-black text-[32px] leading-[46px] tracking-[1.08px] mb-[30px] text-center">
                本レポートではこうしたインサイトを
                <br className="hidden md:block" />
                ご覧いただけます。 是非ご一読下さい。
              </h3>
              <a
                className="banner-btn !no-underline w-[200px] min-h-[40px]"
                href="/resources/state-of-digital-operations-2021/form"
              >
                PDFダウンロード
              </a>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedResourcesStateOfDigitalOperations2021;
